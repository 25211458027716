<template>
  <div>
    <v-col class="cover">
      <div class="action-div">
        <Logo />
        <h2>
          About
        </h2>
      </div>
    </v-col>
    <div class="page-container"
         style="min-height: calc(100vh - 600px)"
    >
      <v-row
        style="margin-bottom: 100px"
        justify="end"
      >
        <v-col class="d-flex align-start flex-column"
               md="7"
               cols="12"
        >
          <h2 class="mb-12">
            {{ title }}
          </h2>
          <p v-for="(item, index) in text"
             :key="index"
             class="text-left"
          >
            {{ item }}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
  name: "About",
  components: {
    Logo,
  },
  data: () => ({
    title: 'Title',
    text: [
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna
      aliqua. Eget est lorem ipsum dolor sit amet consectetur
      adipiscing elit. Dolor purus non enim praesent elementum.
      At quis risus sed vulputate odio ut enim. Ultrices eros
      in cursus turpis massa tincidunt dui. Iaculis nunc sed
      augue lacus viverra. Tortor vitae purus faucibus ornare
      suspendisse sed. Leo a diam sollicitudin tempor id.
      Egestas tellus rutrum tellus pellentesque eu tincidunt
      tortor aliquam. Fermentum odio eu feugiat pretium nibh
      ipsum consequat. Metus vulputate eu scelerisque felis.
      Arcu non sodales neque sodales ut etiam sit amet nisl.
      Laoreet sit amet cursus sit amet. Aliquam ut porttitor
      leo a. Nunc non blandit massa enim nec dui nunc mattis
      enim. Tincidunt vitae semper quis lectus nulla at volutpat.
      Dignissim sodales ut eu sem integer vitae. Tortor posuere
      ac ut consequat semper. Augue eget arcu dictum
      varius duis at consectetur lorem.`,

      `Tincidunt dui ut ornare lectus sit amet est placerat in.
      Sociis natoque penatibus et magnis dis parturient montes
      nascetur ridiculus. Vel pretium lectus quam id leo in vitae.
      Faucibus turpis in eu mi bibendum neque egestas congue quisque.
      Augue neque gravida in fermentum et. Metus dictum at tempor
      commodo ullamcorper a lacus. Sollicitudin aliquam ultrices
      sagittis orci a scelerisque purus. Facilisis sed odio morbi
      quis commodo. Leo vel orci porta non pulvinar neque laoreet
      suspendisse interdum.`
    ]
  })
};
</script>